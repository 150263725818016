<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="role-dialog"
		width="550px">
		<span slot="title" class="dialog-title">
			<span>{{title}}</span>
			<img src="@/assets/images/close.png" alt="" @click="visible = false">
		</span>
		<el-form :model="dataForm" :rules="dataRule" label-width="80px" ref="dataForm">
            <el-form-item label="用户姓名" prop="name">
                <el-input size="small" v-model="dataForm.name" placeholder="请输入姓名" maxlength="10" @blur="getUserCode" :disabled="readonly || usernameFlag"></el-input>
            </el-form-item>
			<el-form-item label="手机号码" prop="tel">
                <el-input size="small" v-model="dataForm.tel" placeholder="请输入手机号" maxlength="11" :disabled="readonly"></el-input>
            </el-form-item>
			<el-form-item label="邮箱地址" prop="email">
                <el-input size="small" v-model="dataForm.email" placeholder="请输入邮箱地址" maxlength="100" :disabled="readonly"></el-input>
            </el-form-item>
			<el-form-item label="用户ID" prop="code">
                <el-input size="small" v-model="dataForm.code" placeholder="建议使用姓名拼音作为用户ID" maxlength="32" :disabled="superAdminFlag"></el-input>
            </el-form-item>
			<el-form-item label="学员ID" prop="stuId">
                <el-input size="small" v-model="dataForm.stuId" placeholder="学员ID" maxlength="32" :disabled="superAdminFlag"></el-input>
            </el-form-item>
			<el-form-item label="所属区域" prop="areaId">
				<el-select size="small" v-model="dataForm.areaId" filterable :disabled="readonly" @change="handleArea">
					<el-option v-for="(item,i) in areaList" :key="i" :value="item.id" :label="item.name"></el-option>
				</el-select>
            </el-form-item>
			<el-form-item label="所属公司" prop="agentId">
				<el-select size="small" v-model="dataForm.agentId" :disabled="readonly" ref="comRef" @change="handleCompany"  filterable reserve-keyword remote clearable :remote-method="remoteMethod">
					<el-option v-for="(item,i) in dealerList" :key="i" :value="item.id" :label="item.name"></el-option>
				</el-select>
            </el-form-item>
			<el-form-item label="省市" prop="">
				<el-select size="small" v-model="dataForm.city" filterable placeholder="请选择省市" :disabled="readonly">
					<el-option
						v-for="item in proviceList"
						:key="item.id"
						:label="item.name"
						:value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="角色选择" prop="roleIdList">
				<el-select size="small" v-model="dataForm.roleIdList" filterable multiple :disabled="readonly">
					<el-option v-for="(item,i) in roleList" :key="i" :value="item.id" :label="item.name"></el-option>
				</el-select>
            </el-form-item>
        </el-form>
		<span slot="footer" class="dialog-footer">
			<el-button class="submit-btn" @click="submit" v-if="title != '查看用户'">确定</el-button>
		</span>
	</el-dialog>
</template>
<script>
import { debounce } from '@/utils/debounce'
import vPinyin from '@/utils/characterToPinYing'
export default {
	data() {
		return {
			title: '新增用户',
			visible: false,
			readonly: false,
			usernameFlag: false,
			areaList: [],
			roleList: [],
			dealerList: [],
			proviceList: [],
			submitUrl: 'sysUserAdd',
			dataForm: {
				name: '',
				tel: '',
				email: '',
				code: '',
				stuId: '',
				agentId: '',
				areaId: '',
				city: '',
				roleIdList: []
			},
			dataRule: {
				name: [
					{required: true,message: '用户姓名不能为空', trigger: "blur"},
					// {pattern: /^[A-Za-z0-9\u4e00-\u9fa5]$/, message: '请输入中文，英文和数字'}
					{pattern: /^[A-Za-z0-9\u4e00-\u9fa5]{1,10}$/, message: '请输入中文，英文和数字'}
				],
				tel: [
					// {required: true,message: '手机号码不能为空', trigger: "blur"},
					{pattern: /^((13[0-9])|(14[0,1,4-9])|(15[0-3,5-9])|(16[2,5,6,7])|(17[0-8])|(18[0-9])|(19[0-3,5-9]))\d{8}$/, message: '请输入正确手机格式'}
				],
				email: [
					// {required: true,message: '邮箱不能为空', trigger: "blur"},
					{pattern: /^[A-Za-z0-9_.-]*([@]+)[A-Za-z0-9_.-]*$/, message: '请输入正确邮箱格式'},
				],
				stuId: [
					{required: true,message: '学员ID不能为空', trigger: "blur"},
					{pattern: /^[0-9]*$/, message: '学员ID必选是整数'}
				],
				roleIdList: [
					{required: true,message: '请选择角色', trigger: "change"}
				],
				areaId: [
					{required: true,message: '请选择区域', trigger: "change"}
				]
			},
			superAdminFlag: false
		}
	},
	methods: {
		init(type,data) {
			this.visible = true;
			this.getRoleList();
			this.$nextTick(() => {
				this.superAdminFlag = JSON.parse(window.sessionStorage.userInfo).superAdminFlag == '1' ? false : true;
				this.$refs.dataForm.resetFields();
				this.dataForm.city = '';
				this.proviceList = [];
				if(type == 'add') {
					this.getAreaList();
					this.getDealerList();
					this.title = '新增用户';
					this.submitUrl = 'sysUserAdd';
					this.readonly = false;
					this.usernameFlag = false;
					delete this.dataForm.id;
					this.getAutoCodeId();
				}
				else if(type == 'edit') {
					this.title = '修改用户';
					this.getUserInfo(data.id);
					this.submitUrl = 'sysUserUpdate';
					this.readonly = false;
					this.usernameFlag = this.superAdminFlag;
				}
				else {
					this.title = '查看用户';
					this.getUserInfo(data.id);
					this.submitUrl = 'sysUserUpdate';
					this.readonly = true;
					this.superAdminFlag = true;
				}
			})

		},
		// 获取用户数据
		getUserInfo(id) {
			let that = this;
			that.$request.get(
				"getUserInfo",
				true,
				{
					id: id
				},
				function (r) {
					if (r.code == "0") {
						that.getDealerList(r.data.agentId,'');
						that.dataForm.id = r.data.id;
						that.dataForm.name = r.data.name;
						that.dataForm.tel = r.data.tel;
						that.dataForm.code = r.data.code;
						that.dataForm.email = r.data.email;
						that.dataForm.stuId = r.data.stuId;
						that.dataForm.agentId = r.data.agentId;
						that.dataForm.areaId = r.data.areaId;
						that.dataForm.city = r.data.city;
						that.getAreaList();
						that.dataForm.roleIdList = [];
						r.data.roleList.forEach(element => {
							that.dataForm.roleIdList.push(element.id)
						});

					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 获取角色数据
		getRoleList() {
			let that = this;
			that.$request.get(
				"sysRoleList",
				true,
				{
					name: '',
					type: '',
					pageNo: 1,
					pageSize: 999
				},
				function (r) {
					if (r.code == "0") {
						that.roleList = r.data.list;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 获取区域数据
		getAreaList() {
			let that = this;
			that.$request.post(
				'getListByCodes',
				true,
				{
					codes: 'areaType'
				},
				function (r) {
					if (r.code == "0") {
						const _areaList = [];
						// 去除新增用户时的技术总部选项 2022-08-19
						for (const area of r.data.areaType) {
							if (area.id !== '1399667050993029120') {
								_areaList.push(area)
							}
						}
						that.areaList = _areaList

						that.areaList.forEach(item => {
							if(that.dataForm.areaId == item.id) {
								that.proviceList = item.areaList;
							}
						})
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 获取省市数据
		handleArea() {
			this.dataForm.agentId = '';
			this.getDealerList();
			this.dataForm.city = '';
			this.areaList.forEach(item => {
				if(this.dataForm.areaId == item.id) {
					this.proviceList = item.areaList;
				}
			})
		},
		// 获取经销商数据
		getDealerList(dealerId,searchKey) {
			let that = this;
			that.$request.post(
				"getDealerLists",
				true,
				{
					areaId: that.dataForm.areaId,
					searchKey: searchKey,
					dealerId: dealerId
				},
				function (r) {
					if (r.code == "0") {
						that.dealerList = r.data;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 所属公司处理
		handleCompany() {
			this.dealerList.forEach(item => {
				if(item.id == this.dataForm.agentId) {
					this.dataForm.city = item.city;
				}
			})
		},
		remoteMethod(query) {
			if (query == '') {
				return
			}
			this.getDealerList(this.dataForm.agentId,query);
		},
		// 获取用户编码
		getUserCode() {
			let code = vPinyin.chineseToPinYin(this.dataForm.name);
			let that = this;
			that.$request.get(
				"sysGetUserCode",
				false,
				{
					code: code
				},
				function (res) {
					if (res.code == '0') {
						that.dataForm.code = res.data;
					}
				}
			)
		},
		// 获取学员id
		getAutoCodeId() {
			let that = this;
			that.$request.post(
				'getAutoCodeId',
				true,
				{
					code: 'stuId'
				},
				function (r) {
					if (r.code == "0") {
						that.dataForm.stuId = r.data.val;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 提交
		submit:debounce(function() {
			if(this.visible == false) {
				return
			}
			this.$refs['dataForm'].validate(valid => {
				if (valid) {
					let that = this;
					that.$request.post(
						that.submitUrl,
						true,
						that.dataForm,
						function (r) {
							if (r.code == "0") {
								that.visible = false;
								that.$emit('getUserList');
								that.$message.success('保存成功');
							} else {
								that.$message.error(r.msg);
							}
						}
					);
				} else {
					return false;
				}
			});
		},1000),
	},
}
</script>
<style lang="scss" scoped>
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.el-form-item{
	margin-bottom: 18px;
	.el-select{
		width: 100%;
	}
}
.submit-btn{
	width: 100%;
	background-color: #117FFC;
	color: #fff;
}
.role-dialog /deep/ .el-dialog{
	border-radius: 10px !important;
}
.role-dialog /deep/ .el-dialog__body{
	padding: 0 20px !important;
}
.role-dialog /deep/ .el-form-item__label{
	// padding: 0 !important;
	// line-height: 24px;
}
.role-dialog /deep/ .el-dialog__footer{
	padding: 20px 20px 40px !important;
}
</style>
